/* eslint-disable @typescript-eslint/no-use-before-define */
import {
	dateRangeService as dateService,
	printPDF,
	FooterTemplate,
	HeaderWithLogo,
	LanguageAndLiteracyLogo
} from '@imaginelearning/web-ui-react';
import { DataFields, UsageServiceIntervals, ManagerEntityTypes } from '@imaginelearning/web-services';
import { GradeLevelToDigit } from '@imaginelearning/web-ui-reporting';
import { TestWindowAbbreviation } from '@imaginelearning/web-reporting';
import { ApiProduct, API_GRADE_LEVELS } from '@imaginelearning/web-api-core';
import { render } from 'react-dom';

(function() {
	angular
		.module('dashboard.student', [
			'manager.modal.factory',
			'ui.notifications',
			'ui.loader',
			'ui.router',
			'utils.date',
			'utils.gradeLevel',
			'api.application.user',
			'api.assessment.provider',
			'api.manager',
			'api.urls',
			'il.web.features',
			'il.web.components',
			'ui.directives.matchParentHeight',
			'react.components',
			'react.dependencies'
		])
		.config(config)
		.controller('StudentDashboardController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider', '$windowProvider'];

	function config($stateProvider, $urlRouterProvider, $windowProvider) {
		$stateProvider
			.state('dashboard.student', {
				url: '/student/:id?navGroupId',
				controller: controller,
				controllerAs: 'model',
				// abstract: true,
				templateUrl: 'dashboards/student/student.html',
				data: {
					bodyClass: 'dashboard',
					contentClass: 'dashboard student'
				}
			})
			.state('dashboard.student.overview', {
				url: '/overview',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/overview.html'
					}
				}
			})
			.state('dashboard.student.usage', {
				url: '/usage?&start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/usage.html'
					}
				}
			})
			.state('dashboard.student.progress', {
				url: '/progress',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/progress.html'
					}
				}
			})
			.state('dashboard.student.progress.achievements', {
				url: '/achievements?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/progress.achievements.html'
					}
				}
			})
			.state('dashboard.student.progress.skills', {
				url: '/skills?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/progress.skills.html'
					}
				}
			})
			.state('dashboard.student.progress.byLesson', {
				url: '/byLesson?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/progress.by.lesson.html'
					}
				}
			})
			.state('dashboard.student.progress.kready', {
				url: '/kready?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/progress.kready.html'
					}
				}
			})

			.state('dashboard.student.assessments', {
				url: '/assessments',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/assessments.html'
					}
				}
			})
			.state('dashboard.student.assessments.overview', {
				url: '/overview',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/assessments.overview.html'
					}
				}
			})
			.state('dashboard.student.assessments.growth', {
				url: '/growth?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/assessments.growth.html'
					}
				}
			})
			.state('dashboard.student.assessments.benchmark', {
				url: '/benchmark?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/assessments.benchmark.html'
					}
				}
			})
			.state('dashboard.student.assessments.lexile', {
				url: '/lexile?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/assessments.lexile.html'
					}
				}
			})
			.state('dashboard.student.portfolio', {
				url: '/portfolio?filter&artifactId',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/portfolio.html'
					}
				}
			})

			.state('dashboard.student.standards', {
				url: '/standards',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/standards.html'
					}
				}
			})
			.state('dashboard.student.activity', {
				url: '/activity',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/activity.html'
					}
				}
			})
			.state('dashboard.student.notes', {
				url: '/notes',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/notes.html'
					}
				}
			})

			.state('dashboard.student.settings', {
				url: '/settings',
				views: {
					'': {
						templateUrl: 'dashboards/student/partials/settings.html'
					}
				}
			});
	}

	controller.$inject = [
		'$scope',
		'$q',
		'urls',
		'$state',
		'$window',
		'$timeout',
		'ApplicationUserService',
		'AuthenticationServiceEvents',
		'NotificationFactory',
		'CurrentDate',
		'$rootScope',
		'Student',
		'schoolYear',
		'GradeLevel',
		'DateRangeService',
		'GeneratePdfModal',
		'ReportingAPI',
		'ReportPrinting',
		'ManagerModalFactory',
		'GroupStudentNavigation',
		'AssessmentProvider',
		'AssessmentProviders',
		'ReactDependenciesService',
		'PendoService'
	];

	function controller(
		$scope,
		$q,
		urls,
		$state,
		$window,
		$timeout,
		ApplicationUserService,
		AuthenticationServiceEvents,
		NotificationFactory,
		CurrentDate,
		$rootScope,
		Student,
		schoolYear,
		GradeLevel,
		DateRangeService,
		GeneratePdfModal,
		ReportingAPI,
		ReportPrinting,
		ManagerModalFactory,
		GroupStudentNavigation,
		AssessmentProvider,
		AssessmentProviders,
		ReactDependenciesService,
		PendoService
	) {
		var model = this;

		model.reactDependenciesService = ReactDependenciesService;
		model.studentUsageReportProps = undefined;
		model.studentSkillsInventoryProps = undefined;
		model.scaledScoreReportProps = undefined;
		model.readingLevelAssessmentReportProps = undefined;
		model.studentBenchmarkReportProps = undefined;
		model.studentDashboardReportProps = undefined;
		model.studentDashboardProps = undefined;
		model.UsageKpiProps = { busy: true, onOpenUsage };
		model.GrowthKpiProps = { busy: true, onOpenGrowth };
		model.ProgressKpiProps = { busy: true, onOpenProgress };
		model.AchievementsKpiProps = { onOpenAchievements: achievementsHeaderClicked };
		model.studentDashboardGrowthReportProps = undefined;
		model.selectedTestWindows = [];
		model.dateService = undefined;

		model.assessmentProviders = AssessmentProviders;
		model.assessmentProvider = '';
		model.groupStudentNavigation = GroupStudentNavigation;
		model.busy = false;
		model.initialized = false;
		model.error = false;
		model.filter = undefined;
		model.artifactId = undefined;
		model.defaultRange = undefined;
		model.student = {};
		model.organizationId = undefined;
		model.currentState = undefined;
		model.hideGrowthTab = false;
		model.isNwea = false;
		model.isThirdPartyAssessment = false;
		model.galileoUrl = $rootScope.appendAuthSourceQueryParam(urls.galileoUrl);
		model.nweaUrl = urls.nweaUrl;
		model.imagineLearningUniversityUrl = urls.imagineLearningUniversityUrl;
		model.userData = _.pick(ApplicationUserService.getUser(), ['id', 'organizationIds']);
		model.isPrinting = false;
		model.session = undefined;

		model.dateRanges = {
			skills: undefined,
			achievements: undefined,
			kready: undefined,
			usageAverage: undefined,
			usagePredicted: undefined,
			assessmentsOverview: undefined,
			assessmentsGrowth: undefined,
			assessmentsBenchmark: undefined,
			assessmentsLexile: undefined,
			progressByLesson: undefined,
			scaledScore: undefined
		};

		model.stateChangeSuccess = stateChangeSuccess;
		model.printUsage = printUsage;
		model.closeManagementTooltip = closeManagementTooltip;
		model.showManagementTooltip = showManagementTooltip;
		model.edit = edit;
		model.growth = {};
		model.onPrint = onPrint;

		angular.element($window).on('keydown', function(event) {
			if (event.ctrlKey && event.key === 'p') {
				event.preventDefault();
				model.onPrint();
			}
		});

		function printUsage() {
			GeneratePdfModal.generate(
				ReportPrinting.usage.student({
					id: model.student.id,
					startDate: model.dateRanges.usageAverage.start,
					endDate: model.dateRanges.usageAverage.end
				}),
				'Generate Usage Report',
				undefined,
				[model.student.firstName, model.student.lastName, 'Usage.pdf'].join(' ')
			);
		}

		function closeManagementTooltip() {
			const quickEditElement = document.querySelector('[data-id="quick_edit"]');
			quickEditElement.classList.remove('hover-state');

			model.managementTooltipProps = undefined;
		}

		function showManagementTooltip() {
			const quickEditElement = document.querySelector('[data-id="quick_edit"]');
			quickEditElement.classList.add('hover-state');

			model.managementTooltipProps = {
				anchorObject: quickEditElement,
				isOpen: true,
				onClose: model.closeManagementTooltip
			};
		}

		function edit() {
			ManagerModalFactory.edit('student', model.student.id);
		}

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);

		function updateDashboardWidgets() {
			ReportingAPI.student
				.get({
					id: model.student.id,
					startDate: model.usageDate,
					endDate: !model.last4Weeks.end || model.last4Weeks.end > CurrentDate.get() ? CurrentDate.get() : model.last4Weeks.end,
					dataFields: [UsageServiceIntervals.weeklyUsage]
				})
				.then(function(result) {
					model.last4weeksData = result;
					model.UsageKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						data: model.last4weeksData,
						dateRange: { ...model.last4Weeks, start: model.usageDate },
						product: 'ILE',
						busy: !model.last4weeksData || !model.last4Weeks,
						displayText: 'Average student usage (minutes) for the last 4 weeks',
						isPrinting: model.isPrinting,
						onOpenUsage
					};
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});

			ReportingAPI.student
				.get({
					id: model.student.id,
					startDate: model.yearToDateRange.start,
					endDate:
						!model.yearToDateRange.end || model.yearToDateRange.end > CurrentDate.get()
							? CurrentDate.get()
							: model.yearToDateRange.end,
					dataFields: [
						DataFields.performance,
						DataFields.progress,
						DataFields.counts,
						DataFields.benchmarkTests,
						DataFields.lexileTestStatuses,
						DataFields.lexile
					]
				})
				.then(function(results) {
					model.yearToDateData = results;
					model.GrowthKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						assessmentProvider: model.assessmentProvider,
						onOpenGrowth: onOpenGrowth,
						onOpenGrowthRla: onOpenGrowthRla,
						onOpenGrowthScaled: onOpenGrowthScaled,
						organizationId: model.organizationId,
						type: ManagerEntityTypes.Student,
						data: model.yearToDateData,
						busy: !model.assessmentProvider || !model.yearToDateData
					};

					model.ProgressKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						data: model.yearToDateData,
						type: ManagerEntityTypes.Student,
						busy: !model.yearToDateData,
						isPrinting: model.isPrinting,
						onOpenProgress
					};
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function refreshModel(id) {
			if (model.busy || model.yearToDateRange !== undefined) {
				return $q.when();
			}

			model.busy = true;
			model.error = false;
			return Student.get(id, {
				additionalFields: [
					'organizations',
					'groups',
					'lexileSettingsBoy',
					'lexileSettingsMoy',
					'lexileSettingsEoy',
					'growthSettingsBoy',
					'growthSettingsMoy',
					'growthSettingsEoy'
				]
			})
				.then(function(result) {
					model.student = result || {};
					model.studentIsLexileAge = GradeLevel.getGradeLevelNumber(model.student.gradeLevel) > 1;
					const intersect = _.intersection(model.student.organizationIds, model.userData.organizationIds);
					model.organizationId = intersect && intersect.length ? intersect[0] : model.student.organizationIds[0];

					model.growthNavParams = {
						districtId: model?.organization?.parentId,
						schoolId: model?.organizationId,
						groupId: model?.groupStudentNavigation?.navGroupId ?? model?.student?.groupIds?.[0],
						studentId: model?.student?.id
					};
					return schoolYear.get(model.organizationId);
				})
				.then(function(result) {
					model.session = result;
					model.dateService = dateService(model.session);

					model.yearToDateRange = model.dateService.yearToDate(result);
					model.last4Weeks = model.dateService.last4Weeks();
					model.lastWeek = model.dateService.lastWeek();
					model.thisWeek = model.dateService.thisWeek();
					model.dateRanges.progressByLesson = model.yearToDateRange;

					model.usageDate = new Date(model.last4Weeks.end.getTime());
					model.usageDate.setHours(0, 0, 0, 0);
					model.usageDate.setDate(model.usageDate.getDate() - 21);

					return AssessmentProvider.getForOrgAndChildren(
						model.organizationId,
						GradeLevel.getGradeLevelNumber(model.student.gradeLevel)
					);
				})
				.then(provider => {
					model.assessmentProvider = provider.defaultAssessmentProvider;
					if (model.student?.gradeLevel && provider.gradeOverrides?.[GradeLevelToDigit[model.student.gradeLevel]]) {
						model.assessmentProvider = model.gradeOverrides?.[GradeLevelToDigit[model.student.gradeLevel]];
					}

					model.hideGrowthTab =
						model.assessmentProvider === AssessmentProviders.ATI || model.assessmentProvider === AssessmentProviders.NWEA;
					model.isThirdPartyAssessment = model.hideGrowthTab;
					model.isNwea = provider.defaultAssessmentProvider === AssessmentProviders.NWEA;
				})
				['catch'](function(error) {
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
				});
		}

		function usageReportDateRangeChanged(dateRange) {
			model.dateRanges.usageAverage = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);
		}

		function skillsInventoryReportDateRangeChanged(dateRange) {
			model.dateRanges.skills = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.skills, $state);
		}

		function scaledScoreReportDateRangeChanged(dateRange) {
			model.dateRanges.assessmentsGrowth = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
		}

		function benchmarkTestLoaded(test) {
			model.growth = test;
		}

		function portfolioFilterChanged(filter) {
			$state.go(
				'dashboard.student.portfolio',
				{ id: model.student.id, filter: filter, navGroupId: model.groupStudentNavigation.navGroupId },
				{ notify: true }
			);
		}

		function printArtifactPage() {
			$window.print();
		}

		function artifactSelected(artifact) {
			model.artifactId = artifact?.id ?? '';
			if (!model.artifactId) {
				model.studentPortfolioProps = undefined;
			} else if (artifact?.artifactType) {
				// Only runs once when artifact is loaded
				PendoService.pendoService.track({
					name: 'ILL | Student Artifact Type',
					data: {
						artifact_type: artifact.artifactType
					}
				});
			}
			$state.go(
				'dashboard.student.portfolio',
				{ id: model.student.id, artifactId: model.artifactId, navGroupId: model.groupStudentNavigation.navGroupId },
				{ notify: true }
			);
		}

		function progressByLessonGroupClicked(link) {
			$window.open(link, '_blank');
		}

		function onOpenUsage() {
			$state.go('dashboard.student.usage', { id: model.student.id }, { notify: true });
		}

		function onOpenGrowth(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.student.assessments.benchmark', { id: model.student.id }, { notify: true });
			}
		}

		function onOpenNewGrowth(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.growth', model.growthNavParams);
			}
		}

		function onOpenGrowthRla(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.student.assessments.lexile', { id: model.student.id }, { notify: true });
			}
		}

		function onOpenGrowthScaled(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.student.assessments.growth', { id: model.student.id }, { notify: true });
			}
		}

		function onOpenProgress() {
			$state.go('dashboard.student.progress.byLesson', { id: model.student.id }, { notify: true });
		}

		function onOpenLessonDetails() {
			$state.go('dashboard.student.progress.byLesson', { id: model.student.id });
		}

		function onOpenKready() {
			$state.go('dashboard.student.progress.kready', { id: model.student.id });
		}
		function achievementsHeaderClicked(studentId, navGroupId) {
			$state.go('dashboard.student.progress.achievements', { id: studentId, navGroupId: navGroupId }, { notify: true });
		}

		function onPortfolioHeaderClicked() {
			$state.go('dashboard.student.portfolio', { id: model.student.id });
		}
		function onProgressByLessonClicked() {
			$state.go('dashboard.student.progress.byLesson', { id: model.student.id }, { notify: true });
		}
		function onSkillsInventoryClicked() {
			$state.go('dashboard.student.progress.skills', { id: model.student.id }, { notify: true });
		}
		function onAchievementsClicked() {
			$state.go('dashboard.student.progress.achievements', { id: model.student.id }, { notify: true });
		}
		function onBenchmarkTestClicked() {
			if ($rootScope.growthReportApi) {
				$state.go('dashboard.growth', { ...model.growthNavParams, test: 'literacy' }, { notify: true });
			} else {
				$state.go('dashboard.student.assessments.benchmark', { id: model.student.id }, { notify: true });
			}
		}
		function onRLAClicked() {
			if ($rootScope.growthReportApi) {
				$state.go('dashboard.growth', { ...model.growthNavParams, test: 'lexile' }, { notify: true });
			} else {
				$state.go('dashboard.student.assessments.lexile', { id: model.student.id }, { notify: true });
			}
		}
		function onScaledScoreClicked() {
			$state.go('dashboard.student.assessments.growth', { id: model.student.id }, { notify: true });
		}
		function onPrintoutsClicked() {
			window.open('/resources/printouts', '_blank');
		}
		function onClassroomActivitiesClicked() {
			window.open('/resources/classroomActivities', '_blank');
		}
		function onReteachingClicked() {
			window.open('/resources/reteachingLessonPlans', '_blank');
		}
		function onGalileoClicked() {
			$window.location.href = urls.galileoUrl;
		}
		function onNWEAClicked() {
			$window.location.href = urls.nweaUrl;
		}

		// PENDO LOCATION_API: remove when using routes
		function onManagementModalOpen(modalType) {
			PendoService.pendoService.updateLocationWithQueryParam(modalType ?? 'managementModal');
		}
		function onManagementModalClose() {
			PendoService.pendoService.revertToBrowserUrl();
		}

		function onSelectedTestWindowsUpdate(windows) {
			model.selectedTestWindows = windows;
		}

		function refreshKpisForPrinting() {
			model.ProgressKpiProps = {
				...model.ProgressKpiProps,
				isPrinting: model.isPrinting
			};

			model.UsageKpiProps = {
				...model.UsageKpiProps,
				isPrinting: model.isPrinting
			};
		}

		function beforePDFPrinting() {
			var contentArea = document.getElementsByClassName('content_area')?.[0];
			model.contentAreaWidth = angular.copy(contentArea.style.width);
			contentArea.style.width = '11in';
			var donutCenter = document.getElementsByClassName('k-chart-donut-center')?.[0];
			var donut = document.getElementsByClassName('donut-chart')?.[0];
			if (donutCenter && donut) {
				var clonedElement = donutCenter.cloneNode(true);
				clonedElement.className = 'remove-after-print';
				clonedElement.style.cssText = donutCenter.style.cssText;
				clonedElement.style = `
					position:absolute;
					top: 7rem;
					z-index: 100;
					left: 7.5rem;
					width: 100px;
				`;
				donut.parentNode.insertBefore(clonedElement, donut);
			}
			refreshKpisForPrinting();
		}

		function afterPDFPrinting() {
			setTimeout(() => {
				model.isPrinting = false;
				var contentArea = document.getElementsByClassName('content_area')?.[0];
				contentArea.style.width = 'auto';
				document.getElementsByClassName('remove-after-print')?.[0]?.remove();
				refreshKpisForPrinting();
				if (!$scope.$$phase) {
					$scope.$apply();
				}
			}, 500);
		}
		function onPrint() {
			Student.ancestors(model.student.id, model.student.organizationIds[0])
				.then(function(result) {
					model.isPrinting = true;
					beforePDFPrinting();

					$timeout(function() {
						const subheader = result.map(entity => entity.name);
						const studentName = `${model.student.lastName}, ${model.student.firstName}`;
						subheader.push(studentName);
						const exportPDFOptions = {
							fileName: `Student Dashboard.pdf`,
							scale: 0.5
						};
						const templateParent = document.createElement('div');
						const testWindowText =
							model.selectedTestWindows.length === 3
								? 'All'
								: model.selectedTestWindows.map(window => TestWindowAbbreviation[window]).join(', ');
						const header = HeaderWithLogo({
							Logo: LanguageAndLiteracyLogo,
							entityName: studentName,
							schoolYear: `${model.session.start.getFullYear()} - ${model.session.end.getFullYear()}`,
							gradeLevel: model.student.gradeLevel,
							label: 'Dashboard',
							subheader: subheader.join(' / '),
							testWindowText
						});
						render(header, templateParent);

						const drawDOMOptions = {
							scale: 0.5,
							forcePageBreak: '.page-break',
							multiPage: true,
							template: ({ pageNum, totalPages }) => {
								return `
								<div class="printing">
									<div style="position: absolute; top: 35px; width: 85%; paddingLeft: 10px;">
									${templateParent.innerHTML}
									</div>
									${FooterTemplate(pageNum, totalPages, 'Imagine Language & Literacy')}
								</div>
							`;
							}
						};

						setTimeout(() => {
							const printElement = document.querySelector('.content_area');
							printPDF(printElement, drawDOMOptions, exportPDFOptions, afterPDFPrinting);
						}, 1000);
					});
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			var gradeLevel;
			model.currentState = toState.name;

			if (toState.name && toState.name === 'dashboard.student') {
				$state.go('dashboard.student.overview', toParams, { notify: true });
			}

			if (toState.name && toState.name === 'dashboard.student.assessments') {
				$state.go('dashboard.student.assessments.benchmark', toParams, { notify: true });
			}

			if (toState.name && toState.name === 'dashboard.student.progress') {
				$state.go('dashboard.student.progress.byLesson', toParams, { notify: true });
			}

			if (toParams && toParams.id && toState.name && toState.name.match(/dashboard.student./gi)) {
				var promise = $q.when(),
					_toParams = toParams,
					_toState = toState;

				if (toParams.navGroupId && toParams.navGroupId.length) {
					if (toState.name && toState.name === 'dashboard.student.progress.kready') {
						gradeLevel = 'PreK';
					}
					promise = GroupStudentNavigation.refresh(
						toParams && toParams.navGroupId ? toParams.navGroupId : undefined,
						toParams.id,
						gradeLevel
					);

					model.studentKindergartenReadinessProps = {
						config: ReactDependenciesService.apiConfig(),
						studentId: toParams.id
					};
				}

				if (toParams && toParams.filter) {
					model.filter = toParams.filter;
				}

				model.artifactId = toParams && toParams.artifactId ? toParams.artifactId : undefined;

				// stash away range in params to allow drill down continuity
				model.defaultRange =
					toParams.start !== undefined && toParams.end !== undefined
						? DateRangeService.getRange(toParams.start, toParams.end || CurrentDate.get())
						: undefined;

				promise.then(function() {
					refreshModel(_toParams.id).then(function() {
						if (_toState.name === 'dashboard.student.overview') {
							const organizationId = model.student.organizationIds[0];

							updateDashboardWidgets();

							model.studentDashboardProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: toParams.id,
								navGroupId: toParams.navGroupId,
								onClickAchievementsHeader: achievementsHeaderClicked,
								params: {
									startDate: model.yearToDateRange.start,
									endDate: model.yearToDateRange.end
								}
							};

							model.AchievementsKpiProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: toParams.id,
								navGroupId: toParams.navGroupId,
								dateRange: { start: model.yearToDateRange.start, end: model.yearToDateRange.end },
								product: ApiProduct.ILE,
								onOpenAchievements: achievementsHeaderClicked
							};

							model.studentDashboardGrowthReportProps = {
								config: ReactDependenciesService.apiConfig(),
								assessmentProvider: model.assessmentProvider,
								studentId: toParams.id,
								organizationId: organizationId,
								growthApi: $rootScope.growthReportApi,
								onOpenGrowth: onOpenNewGrowth,
								onSelectedTestWindowsUpdate: onSelectedTestWindowsUpdate
							};

							model.sidePanelProps = {
								assessmentProvider: model.assessmentProvider,
								config: ReactDependenciesService.apiConfig(),
								organizationId: organizationId,
								sourceType: 'student',
								source: {
									...model.student,
									gradeLevel: $rootScope.growthReportApi ? API_GRADE_LEVELS.PreK : model.student.gradeLevel
								},
								userId: model.userData.id,
								onGalileoClicked,
								onNWEAClicked,
								onPortfolioHeaderClicked,
								onProgressByLessonClicked,
								onSkillsInventoryClicked,
								onAchievementsClicked,
								onBenchmarkTestClicked,
								onRLAClicked,
								onScaledScoreClicked,
								onPrintoutsClicked,
								onClassroomActivitiesClicked,
								onReteachingClicked,
								onManagementModalOpen,
								onManagementModalClose,
								params: {
									startDate: model.session.start,
									endDate: model.session.start,
									product: 'ILE'
								}
							};
						}
						if (_toState.name === 'dashboard.student.progress.achievements') {
							model.dateRanges.achievements = model.defaultRange || model.dateRanges.achievements || model.thisWeek;
							DateRangeService.updateRangeParams(model.dateRanges.achievements, $state);
						}
						if (_toState.name === 'dashboard.student.progress.skills') {
							model.dateRanges.skills = model.defaultRange || model.dateRanges.skills || model.thisWeek;
							DateRangeService.updateRangeParams(model.dateRanges.skills, $state);

							model.studentSkillsInventoryProps = {
								dateRange: model.dateRanges.skills,
								config: ReactDependenciesService.apiConfig(),
								studentDetails: { ...model.student, name: `${model.student.firstName} ${model.student.lastName}` },
								session: model.session,
								onDateRangeChanged: skillsInventoryReportDateRangeChanged
							};
						}
						if (_toState.name === 'dashboard.student.progress.byLesson') {
							model.dateRanges.progressByLesson =
								model.defaultRange || model.dateRanges.progressByLesson || model.yearToDateRange;
							DateRangeService.updateRangeParams(model.dateRanges.progressByLesson, $state);

							model.studentProgressByLessonProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: model.student.id,
								groupId: model.groupStudentNavigation.navGroupId,
								onLessonGroupClick: progressByLessonGroupClicked,
								simpleViewAvailable: true
							};
						}
						if (_toState.name === 'dashboard.student.usage') {
							model.dateRanges.usageAverage = model.defaultRange || model.dateRanges.usageAverage || model.last4Weeks;
							DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);

							model.studentUsageReportProps = {
								config: ReactDependenciesService.apiConfig(),
								entityDetails: { ...model.student, name: `${model.student.firstName} ${model.student.lastName}` },
								session: model.session,
								onDateRangeChanged: usageReportDateRangeChanged,
								params: {
									startDate: model.dateRanges.usageAverage.start,
									endDate: model.dateRanges.usageAverage.end,
									product: 'ILE'
								}
							};
						}
						if (_toState.name === 'dashboard.student.usage.predicted') {
							model.dateRanges.usagePredicted = model.defaultRange || model.dateRanges.usagePredicted || model.last4Weeks;
							DateRangeService.updateRangeParams(model.dateRanges.usagePredicted, $state);
						}
						if (_toState.name === 'dashboard.student.assessments.growth') {
							model.dateRanges.assessmentsGrowth =
								model.defaultRange || model.dateRanges.assessmentsGrowth || model.yearToDateRange;
							DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
							model.scaledScoreReportProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: model.student.id,
								session: model.session,
								initialDateRange: model.dateRanges.assessmentsGrowth,
								onDateRangeChanged: scaledScoreReportDateRangeChanged,
								studentGrade: model.student.gradeLevel
							};
						}
						if (_toState.name === 'dashboard.student.assessments.benchmark') {
							model.dateRanges.assessmentsBenchmark =
								model.defaultRange || model.dateRanges.assessmentsBenchmark || model.yearToDateRange;
							DateRangeService.updateRangeParams(model.dateRanges.assessmentsBenchmark, $state);
							model.studentBenchmarkReportProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: model.student.id,
								startDate: model.dateRanges.assessmentsBenchmark.start,
								endDate: model.dateRanges.assessmentsBenchmark.end,
								onTestLoaded: benchmarkTestLoaded,
								organizationId: model.organizationId || model.student.organizationIds[0]
							};
						}
						if (_toState.name === 'dashboard.student.assessments.lexile') {
							model.dateRanges.assessmentsLexile =
								model.defaultRange || model.dateRanges.assessmentsLexile || model.yearToDateRange;
							DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexile, $state);
							model.readingLevelAssessmentReportProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: model.student.id,
								startDate: model.dateRanges.assessmentsLexile.start,
								endDate: model.dateRanges.assessmentsLexile.end
							};
						}
						if (_toState.name === 'dashboard.student.assessments.overview') {
							model.dateRanges.assessmentsOverview =
								model.defaultRange || model.dateRanges.assessmentsOverview || model.yearToDateRange;
							DateRangeService.updateRangeParams(model.dateRanges.assessmentsOverview, $state);
						}
						if (_toState.name === 'dashboard.student.portfolio') {
							model.studentPortfolioProps = {
								config: ReactDependenciesService.apiConfig(),
								studentId: model.student.id,
								studentDisplayName: `${model.student.firstName} ${model.student.lastName}`,
								params: { ...toParams, artifactId: model.artifactId },
								onFilterClick: portfolioFilterChanged,
								onPrintPage: printArtifactPage,
								onArtifactSelect: artifactSelected,
								session: model.session
							};
						}

						if (_toState.name === 'dashboard.student.overview') {
							model.studentDashboardReportProps = {
								config: ReactDependenciesService.apiConfig(),
								gradeLevel: model.student.gradeLevel,
								studentId: model.student.id,
								onOpenLessonDetails,
								onOpenKready,
								session: model.session
							};
						}
					});
				});
			}
		}

		$scope.$watchCollection(
			function() {
				return model.dateRanges;
			},
			function(value, oldValue) {
				if (!angular.equals(value, oldValue)) {
					if ($state.current.name === 'dashboard.student.progress.achievements') {
						DateRangeService.updateRangeParams(value.achievements, $state);
					}
					if ($state.current.name === 'dashboard.student.progress.skills') {
						DateRangeService.updateRangeParams(value.skills, $state);
					}
					if ($state.current.name === 'dashboard.student.progress.byLesson') {
						DateRangeService.updateRangeParams(value.progressByLesson, $state);
					}
					if ($state.current.name === 'dashboard.student.usage') {
						DateRangeService.updateRangeParams(value.usageAverage, $state);
					}
					if ($state.current.name === 'dashboard.student.usage.predicted') {
						DateRangeService.updateRangeParams(value.usagePredicted, $state);
					}
					if ($state.current.name === 'dashboard.student.assessments.growth') {
						DateRangeService.updateRangeParams(value.assessmentsGrowth, $state);
					}
					if ($state.current.name === 'dashboard.student.assessments.benchmark') {
						DateRangeService.updateRangeParams(value.assessmentsBenchmark, $state);
					}
					if ($state.current.name === 'dashboard.student.assessments.lexile') {
						DateRangeService.updateRangeParams(value.assessmentsLexile, $state);
					}
				}
			},
			true
		);
	}
})();
